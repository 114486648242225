import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Auth0Provider } from "@auth0/auth0-react";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<Auth0Provider
				domain={process.env.REACT_APP_AUTH0_DOMAIN}
				clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
				audience={process.env.REACT_APP_AUTH0_AUDIENCE || ''}
				redirectUri={window.location.origin + "/portal"}
				cacheLocation={"memory"}
				useRefreshTokens={true}
			>
				<App />
			</Auth0Provider>
		</BrowserRouter>
	</React.StrictMode >
);
