import {
	Navigate,
	Routes,
	Route
} from "react-router-dom";
import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const PortalRedirect = () => {
	const { user, isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently } = useAuth0();

	useEffect(() => {
		// create billing session with Stripe
		async function getStripePortalSession() {
			const token = await getAccessTokenSilently();
			// console.log(token);
			const sessionRequest = {
				method: "POST",
				headers: {
					"Authorization": `Bearer ${token}`
				}
			}

			try {
				const session = await fetch(`${process.env.REACT_APP_API_URL}/StripePortal`, sessionRequest);
				if (session.ok) {
					let response = await session.json();
					// console.log(response);
					// successfully created session, redirect to short lived session URL
					window.location = response.redirectUrl;
				} else {
					// received API error, redirect to Elevate dashboard
					console.log("Session response not OK: ", session);
					window.location = process.env.REACT_APP_ELEVATE_URL;
				}
			} catch (e) {
				// an unexpected error occured, redirect to the Elevate dashboard
				console.log('Failed to create portal session: ', e);
				window.location = process.env.REACT_APP_ELEVATE_URL;
			}
		}

		if (isLoading) {
			console.log('Auth0 is loading...');
			return;
		}

		if (!isAuthenticated) {
			console.log('Redirecting to login page...');
			loginWithRedirect();
			return;
		}

		if (!isLoading && isAuthenticated && user != null) {
			console.log("Creating billing portal session for: ", user?.email);
			getStripePortalSession();
		}
	}, [getAccessTokenSilently, isAuthenticated, isLoading, loginWithRedirect, user]);

	if (isLoading) {
		return <div>Loading ...</div>
	}

	if (!isAuthenticated) {
		return <div>Auth required ...</div>
	}

	return (
		<div>
			Loading ...
		</div>
	);
};

export default function App() {
	return (
		<Routes>
			<Route exact path="/" element={<Navigate to="/portal" replace>Portal</Navigate>} />
			<Route exact path="/portal" element={<PortalRedirect />} />
		</Routes>
	);
};